export const columns = [
	{
		prop: 'carModelName',
		label: '车型名称',
		width: '280px',
	},
	{
		prop: 'carNum',
		label: '自编号',
		width: '140px',
	},
	{
		prop: 'licensePlateNum',
		label: '车牌号',
		width: '140px',
	},
	{
		prop: 'carColor',
		label: '颜色',
	},
	{
		prop: 'driverName',
		label: '司机姓名',
		width: '120px',
	},
	{
		prop: 'maintainerName',
		label: '维保专员',
		width: '120px',
	},
	{
		prop: 'createUserName',
		label: '交车负责人',
		width: '120px',
	},
	{
		prop: 'deliveryTime',
		label: '交车时间',
		width: '250px',
	},
	{
		prop: 'statusName',
		label: '交车状态',
		width: '140px',
	},
	{
		prop: 'deliveryResults',
		label: '交车结果',
		width: '140px',
	},

	{
		prop: 'carMileage',
		label: '公里数（KM）',
		width: '140px',
	},
	{
		prop: 'belongCompanyName',
		label: '所属公司',
		width: '280px',
	},
	{
		prop: 'operation',
		label: '操作',
		width: '80px',
		fixed: 'right',
	},
]
export const setting = {
	expand: true,
	filters: [
		{
			attr: 'keyword',
			label: '车辆信息',
			type: 'search',
			placeholder: '输入车型名称、车牌、自编号、司机姓名、手机号搜索',
			clearable: true,
			width: 358,
		},
		{
			attr: 'deliveryTime',
			label: '交车时间',
			type: 'date',
		},
		{
			attr: 'status',
			label: '交车状态',
			type: 'select',
			options: [
				{ name: '出库审批中', code: 1 },
				{ name: '出库拒绝', code: 2 },
				{ name: '出库同意', code: 3 },
			],
		},
		{
			attr: 'createUser',
			label: '交车负责人',
			type: 'select',
			options: [],
		},
		{
			attr: 'belongCompanyIds',
			label: '所属公司',
			type: 'multi-select',
			placeholder: '选择所属公司',
			width: 320,
			options: [],
		},
		{
			attr: 'deliveryResults',
			label: '交车结果',
			type: 'select',
			options: [
				{ name: '交车出库', code: 1 },
				{ name: '退出运营', code: 2 },
			],
		},
	],
}

export const form = [
	{
		prop: 'licensePlateNum',
		label: '车牌号',
		required: true,
		component: 'el-select-v2',
		custom: true,
		attrs: { placeholder: '请选择车牌号', filterable: true, options: [] },
	},
	{
		prop: 'createUserName',
		label: '交车人',
		component: 'el-input',
		attrs: { disabled: true },
	},
	{
		prop: 'carModelName',
		label: '车辆车型',
		component: 'el-input',
		cWidth: 500,
		attrs: { disabled: true },
	},
	{
		prop: 'carNum',
		label: '自编号',
		component: 'el-input',
		attrs: { disabled: true },
	},
	{
		prop: 'carColor',
		label: '车辆颜色',
		component: 'el-input',
		attrs: { disabled: true },
	},
	{
		prop: 'driverSupportName',
		label: '司服伙伴',
		// required: true,
		component: 'el-input',
		attrs: { disabled: true },
	},
	{
		prop: 'maintainerName',
		label: '维保专员',
		component: 'el-input',
		attrs: { disabled: true },
	},
	{
		prop: 'carMileage',
		label: '车辆里程(KM)',
		required: true,
		component: 'el-input',
		attrs: { maxLength: 7, placeholder: '请输入车辆里程' },
	},
	{
		prop: 'deliveryResults',
		label: '交车结果',
		required: true,
		component: 'el-select',
		options: [
			{ label: '交车出库', value: 1 },
			{ label: '退出运营', value: 2 },
		],
		attrs: { placeholder: '请选择交车结果' },
	},
	{
		prop: 'deliveryTime',
		label: '交车时间',
		component: 'el-input',
		attrs: { disabled: true },
	},
]
