<template>
	<div class="container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">出库交车</div>
				<div class="header-button">
					<el-button type="success" @click="onCreate" v-permission="'pc/car-center/out-store/save'">
						<el-icon class="icon-plus"><Plus /></el-icon>
						新增出库
					</el-button>
					<el-button
						type="primary"
						@click="onExit"
						v-permission="'pc/car-center/out-store/exit-operations'"
					>
						退出运营
					</el-button>
					<el-button
						:loading="loading"
						@click="onExport"
						v-permission="'pc/car-center/out-store/export'"
					>
						导出Excel
					</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="setting" @change="onChangeFilter" />
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					:columns="columns"
					:data="tableData"
					:params="{ page }"
					autoWidth
				>
					<template #carModelName="{ value, row }">
						<span class="click" @click="onDetail(row)">{{ value }}</span>
					</template>
					<template #operation="{ row }">
						<span v-permission="'pc/car-center/out-store/edit'" class="click" @click="onEdit(row)">
							编辑
						</span>
					</template>
					<template #deliveryResults="{ value }">
						<span>{{ { 1: '交车出库', 2: '退出运营' }[value] }}</span>
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
		<delivery-form ref="formRef" @on-refresh="onRefresh" />
	</div>
</template>

<script name="VehicleDelivery">
import { ref, defineComponent, inject, onActivated, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router'
import { setting as s, columns } from './config'
import { InitTable, useFilter } from '@/utils/hooks.js'
import DeliveryForm from './form.vue'
import { handleExport } from '@/utils/util'
import {
	assetCarStockOutList,
	deliveryUserList,
	exportStockOut,
	companySelect,
} from '@/api/vehicle'

export default defineComponent({
	name: 'VehicleDelivery',
	components: {
		DeliveryForm,
	},
	setup() {
		const $message = inject('$message')
		const formRef = ref(null)
		const params = {}
		const setting = ref(s)
		const loading = ref(false)
		const table = new InitTable(assetCarStockOutList, params)
		const methods = {
			onChangeFilter(params) {
				console.log('onChangeFilter', params)
				const date = {}
				;['deliveryTime'].forEach(key => {
					const data = params[key]
					if (Array.isArray(data) && data.length) {
						const [start, end] = data
						date[`${key}Start`] = start
						date[`${key}End`] = end
					} else {
						delete params[`${key}Start`]
						delete params[`${key}End`]
					}
				})
				table.onChangeFilter({ ...params, ...date })
			},
			onCreate() {
				formRef.value.open()
			},
			onRefresh() {
				table.fetchList()
			},
			onEdit(row) {
				formRef.value.open(row.id, 'edit')
			},
			onExit() {
				formRef.value.open(undefined, 'exit')
			},
			onDetail(row) {
				formRef.value.open(row.id, 'detail')
			},
			async getUserList() {
				const res = await deliveryUserList()
				if (res.code === 200) {
					const createUser = setting.value.filters.find(item => item.attr === 'createUser')
					createUser.options = res.data.map(item => ({ name: item.userName, code: item.userId }))
				}
			},
			async onExport() {
				loading.value = true
				try {
					const res = await exportStockOut({ ...table.params })
					if (res) handleExport(res, '出库交车')
				} catch (err) {
					$message.error(err.message)
				} finally {
					loading.value = false
				}
			},
			async fetchSelect() {
				const { data, err } = await useFilter(() => companySelect(true), {
					name: 'companyName',
					code: 'companyId',
				})
				if (err) $message.error(err.message)
				const company = setting.value.filters.find(item => item.attr === 'belongCompanyIds')
				company.options = data
			},
		}
		onActivated(() => {
			methods.getUserList()
			methods.fetchSelect()
			const route = useRoute()
			const query = route.query
			if (Object.keys(query).length > 0) {
				const data = {}
				if (query.deliveryTime) {
					data.deliveryTime = [`${query.deliveryTime} 00:00:00`, `${query.deliveryTime} 23:59:59`]
				}
				if (query.result) {
					data.deliveryResults = Number(query.result)
				}
				data.belongCompanyIds = query?.companyIds?.split(',') || []
				const ins = getCurrentInstance()
				ins.refs.filterRef && ins.refs.filterRef.manualSetParams(data)
			} else {
				table.fetchList()
			}
		})
		table.fetchList()
		methods.getUserList()
		methods.fetchSelect()
		return {
			setting,
			columns,
			loading,
			formRef,
			...table.res,
			...methods,
		}
	},
})
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
	.click {
		cursor: pointer;
		color: #409eff;
	}
}
</style>
